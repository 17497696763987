<template>
  <div
    v-if="$route.query.payment_intent"
    style="background-color: #F5F5F5">
    <v-container
      id="comprovant"
      v-if="id" class="pt-12">
      <div class=" align-center"
           :class="$vuetify.breakpoint.mdAndUp ? 'd-flex' : ''"
           style="width: 100%;">
        <div style="flex: 1">
        <span class="font-weight-bold font-rubik"
              style="font-size: 36px !important;color: #256EFF; letter-spacing: -1px !important;">examenselectivitat</span>
        </div>
        <v-spacer></v-spacer>
        <div class="d-flex flex-column">
          <span class="align-end text-end font-weight-medium">Confirmació de la comanda</span>
          <v-card flat class="pa-4 d-flex flex-column rounded-lg mt-1" color="grey lighten-3">
              <span class="caption text-end">
              Número de comanda
            </span>
            <span class="caption text-end blue--text text--darken-2 font-weight-medium">{{ id }}</span>
          </v-card>
        </div>
      </div>
      <div class="d-flex flex-column mt-12">
        <span style="font-size: 20px" class="font-weight-medium">Hola {{ name }},</span>
        <span>Gràcies per la confiança. Et volem fer saber que la teva comanda ha sigut satisfactòria i que s'està
        preparant per a l'enviament.
          <!--          <span>-->
          <!--          Si vols la factura o tens qualsevol incidència, envia'ns un missatge a-->
          <!--            <a href="mailto:contacte@examenselectivitat.cat" target="_top">-->
          <!--              contacte@examenselectivitat.cat</a>.-->
          <!--            </span>-->
      </span>

        <div class="mt-12 mx-auto"
             style="max-width: 600px; width: 100%"
        >
          <span class="font-weight-medium px-0 font-rubik"
                style="font-size: 19px"
          >Resum de la comanda</span>
          <v-sheet class="rounded-lg shadow-small mt-3 px-7 py-3"
                   style="border-top: #256EFF solid 4px; max-width: 600px"
          >
            <div v-for="item in items"
                 class="pa-3 d-flex align-center"
                 width="100%"
            >
              <v-img :src="item.src"
                     width="90"
                     height="90"
                     max-width="90"
                     class="mr-3"
              ></v-img>
              <span class="font-rubik">
            {{ item.title }}
              </span>
            </div>
          </v-sheet>

          <div class="d-flex flex-column align-end mt-3">
            <span class="font-rubik font-weight-medium">Total: {{ (amount / 100).toFixed(2) }} €</span>
            <span class="font-rubik" style="font-size: 14px">(IVA inclòs)</span>
          </div>
        </div>

        <div class="mt-12 mx-auto"
             style="max-width: 600px; width: 100%"
        >
          <span class="font-weight-medium px-0 font-rubik"
                style="font-size: 19px">
        Sobre l'enviament
          </span>

          <v-sheet style="border-top: #256EFF solid 4px; max-width: 600px"
                   class="mt-2 pa-10 rounded-lg shadow-small mx-auto">
            <div class="d-flex align-end">
              <span class="font-weight-medium col-4 px-0">La teva comanda serà enviada a:</span>
              <span class="ml-2 grey--text text--darken-2 col-8 px-0"> {{ address }}</span>
            </div>
            <div class="d-flex align-end">
              <span class="font-weight-medium col-4 px-0">El teu enviament:</span>
              <span class="ml-2 grey--text text--darken-2 col-8 px-0">{{ shipping }}. 1-2 dies si has demanat 3 dossiers o més.</span>
            </div>
            <div class="d-flex align-end">
              <span class="font-weight-medium col-4 px-0">Seguiment de la comanda:</span>
              <span class="ml-2 grey--text text--darken-2 col-8 px-0">Quan sigui entregada al servei de correus, un correu electrònic amb el seguiment de la comanda serà enviat.</span>
            </div>
          </v-sheet>

        </div>


        <div class="mt-9 text-center">T'hem enviat un correu amb el comprovant d'aquesta comanda a <span
          style="color: #256EFF">{{ email }}</span>. <br>
        </div>

        <div class="d-flex flex-wrap mx-auto my-12"
             style="max-width: 650px; width: 100%"
        >
          <div class="d-flex flex-column mx-auto">
        <span>
          Si vols imprimir o guardar el comprovant de la comanda.
        </span>
            <v-btn
              @click="downloadComprovant"
              depressed
              color="#DFE2E4"
              class="mt-3 text-none mx-auto" style="width: fit-content">
              Imprimir comprovant
            </v-btn>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import {getComprovantHTML} from "./getComprovantHTML";

export default {
  name: "SuccessfulPayment",
  async mounted() {
    if (this.$route.query.payment_intent) {
      this.$root.cart = 0;
      console.log(this.$route.path)
      this.$cookies.set("cart", {})
      await this.fetchInfo();
    }
  },
  data() {
    return {
      customer: null,
      id: null,
      // shipping: null,
      payment: null,


      name: null,
      address: null,
      shipping: null,
      email: null,
      amount: null,
      items: []
    }
  },
  methods: {
    async fetchInfo() {
      const {data} = await this.axios.get("/retreive-completed-order", {
        params: {
          intent_id: this.$route.query.payment_intent
        }
      })
      this.id = data.id;
      this.name = data.name
      this.address = data.address;
      this.shipping = data.shipping;
      this.email = data.email;
      this.amount = data.amount;

      const text = data.desc
      // const text = "1x Quadern Matemàtiques C.S. Selectivitat 1x Quadern Llatí Selectivitat 1x Quadern Història de la Filosofia Selectivitat 1x Quadern Història de l'Art Selectivitat 1x Quadern Economia de l'Empresa Selectivitat 1x Quadern Geografia Selectivitat 1x Quadern Ciències de la Terra Selectivitat 1x Quadern Dibuix Tècnic Selectivitat 1x Quadern Física Selectivitat 1x Quadern Matemàtiques Selectivitat 1x Quadern Química Selectivitat 1x Quadern Tecnologia industrial Selectivitat 1x Quadern Biologia Selectivitat 1x Quadern Història Selectivitat 1x Quadern Llengua Catalana Selectivitat 1x Quadern Llengua Castellana Selectivitat 1x Quadern Anglès Selectivitat 1x Quadern Cultura Audiovisual Selectivitat 1x Quadern Fonaments de les arts Selectivitat 1x Quadern Electrotècnia Selectivitat 1x Quadern Literatura castellana Selectivitat 1x Quadern Literatura catalana Selectivitat 1x Quadern Anàlisi musical Selectivitat 1x Quadern Dibuix artístic Selectivitat 1x Quadern Disseny Selectivitat 1x Quadern Alemany Selectivitat 1x Quadern Francès Selectivitat 1x Quadern Grec Selectivitat 1x Quadern Italià Selectivitat";

// Regular expression to match each order
      const regex = /(\d+x\sQuadern\s(.(?!Selectivitat))+\sSelectivitat)/g;

// Use match to find all matches in the string
      const orders = text.match(regex);

      console.log(orders);

      this.items = []
      orders.forEach((order, idx) => {
        const title = order
        console.log(title)

        let b = title.match(/Quadern\s(.+)\sSelectivitat/)[1]
        if (b) {
          let subject_name = b.charAt(0).toUpperCase() + b.slice(1).toLowerCase()
          subject_name = subject_name.replace("Matemàtiques c.s.", "Matemàtiques CC.SS.")

          const src = `https://examenselectivitat.cat:3000/api/images/other/${subject_name}_examens_2024.webp`

          this.items.push({
            title: title.replace("Matemàtiques C.S.", "Matemàtiques CC.SS."),
            src: src
          })
        }
      })
    },
    downloadComprovant() {
      // const html = getComprovantHTML(this.id, this.name, this.address, this.shipping)
      //
      // var a = window.open('', '', 'height=600,width=1100');
      // a.document.write('<html><body>');
      // a.document.write(html);
      // a.document.write('</body></html>');
      // a.document.close();
      // a.focus();
      // a.print();

      window.print();

    },
  },

}
</script>

<style scoped>

</style>
